import { useState } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Password from './Routes/Password';
import Intro from './Routes/Intro';
import Video1 from './Routes/Video1';
import Video2 from './Routes/Video2';
import Video3 from './Routes/Video3';
import Video4 from './Routes/Video4';
import Video5 from './Routes/Video5';
import Video6 from './Routes/Video6';
import Video7 from './Routes/Video7';
import Video8 from './Routes/Video8';
import DotRing from './Components/DotRing/DontRing';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    return (
      <>
        <DotRing />
        <Password onUnlock={() => setIsAuthenticated(true)} />
      </>
    );
  }

  return (
    <Router>
      <PrincipalPageContainer>
        <DotRing />
        <Routes>
          <Route path="/ilhavo/intro" element={<Intro />} />
          <Route path="/ilhavo/episodio1" element={<Video1 />} />
          <Route path="/ilhavo/episodio2" element={<Video2 />} />
          <Route path="/ilhavo/episodio3" element={<Video3 />} />
          <Route path="/ilhavo/episodio4" element={<Video4 />} />
          <Route path="/ilhavo/episodio5" element={<Video5 />} />
          <Route path="/ilhavo/episodio6" element={<Video6 />} />
          <Route path="/ilhavo/episodio7" element={<Video7 />} />
          <Route path="/ilhavo/episodio8" element={<Video8 />} />
          <Route
            path="*"
            element={<Navigate replace to="/ilhavo/episodio1" />}
          />
        </Routes>
      </PrincipalPageContainer>
    </Router>
  );
}

export default App;

const PrincipalPageContainer = styled.div``;

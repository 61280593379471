import { useContext } from 'react';
import styled from 'styled-components';
import { MouseContext } from '../../context/mouse-context';
import useMousePosition from '../../hooks/useMousePosition';

const DotRing = () => {
  const { x, y } = useMousePosition();
  const { cursorType } = useContext(MouseContext);

  return (
    <>
      <Ring
        style={{ left: `${x}px`, top: `${y}px` }}
        hovered={cursorType === 'hovered'}
      />
      <Dot
        style={{ left: `${x}px`, top: `${y}px` }}
        hovered={cursorType === 'hovered'}
      />
    </>
  );
};

export default DotRing;

const Ring = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid white;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;

  ${({ hovered }) =>
    hovered &&
    `
    width: 50px;
    height: 50px;
    border-width: 3px;
    border-color: lightgray;
  `}
`;

const Dot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;

  ${({ hovered }) =>
    hovered &&
    `
    display: none;
  `}
`;

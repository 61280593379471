import { useContext, useState } from 'react';
import styled from 'styled-components';
import { MouseContext } from '../../context/mouse-context';

const VIDEO_WIDTH = 70; // PERCENTAGE
const HEADER_MARGIN = (100 - VIDEO_WIDTH) / 2;

const VideoPageComponent = ({ onUnlock }) => {
  const { cursorChangeHandler } = useContext(MouseContext);

  const [input, setInput] = useState('');

  const correctPassword = process.env.REACT_APP_PASSWORD;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input === correctPassword) {
      localStorage.setItem('authenticated', 'true'); // Save authentication state
      onUnlock();
    } else {
      alert('Wrong password!');
    }
  };

  return (
    <PageContainer>
      <Header>
        <LogoContainer
          onMouseEnter={() => cursorChangeHandler('hovered')}
          onMouseLeave={() => cursorChangeHandler('')}
        >
          <img src="/images/logo.png" alt="" />
        </LogoContainer>
      </Header>
      <div>
        <h2>Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">Enter</button>
        </form>
      </div>
      <Footer>
        <div
          onMouseEnter={() => cursorChangeHandler('hovered')}
          onMouseLeave={() => cursorChangeHandler('')}
        >
          <span>Todos os direito reservados | Missão Sem Titulo</span>
        </div>
      </Footer>
    </PageContainer>
  );
};

export default VideoPageComponent;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
`;
const Header = styled.div`
  width: 100%;
  height: 20vh;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Footer = styled(Header)`
  display: flex;
  align-items: end;
  justify-content: center;
  height: 10vh;

  > div {
    margin-bottom: 12px;
  }

  span {
    font-size: 12px;
  }
`;

const LogoContainer = styled.div`
  height: 80%;
  margin-left: ${HEADER_MARGIN}%;

  img {
    height: 100%;
  }
`;

import VideoPageComponent from '../../Components/VideoPage';

const VideoPage = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.codeahead.pt/clients/pontocoletivo/videos/intro.mp4"
      title="Introdução"
    />
  );
};

export default VideoPage;
